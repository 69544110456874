import { useEffect, useRef, createContext } from 'react'
import './AboutSection.scss'
import aboutImage from '../../images/aboutUsImage.png'

var aboutRef = createContext()

const AboutSection = () => {
  const aboutImageRef = useRef()
  aboutRef = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting)
          entry.target.classList.add("fadeIn")
      })
    }, {
      threshold: 0.5
    })
    observer.observe(aboutImageRef.current)
  }, [])

  return (
    <section className='aboutSection' ref={aboutRef}>
      <div className="aboutLeft">
        <img 
          src={aboutImage} 
          className='aboutImage'
          ref={aboutImageRef} 
          alt='About'
          width={750} height={450}/>
      </div>
      <div className="aboutRight">
        <div className="header">
          About Us
        </div>
        <div className="body">
          Casual Free Games development center designing and developing Casual and HyperCasual games for all the age groups. We believe in meeting international standards and we have an in house quality control process and separate quality assurance group to monitor all the aspects of quality.
          Our vision and aim is to provide best Casual Games and implement the effective strategies and provide customer satisfaction which is shown in our creative and innovative way by our young and energetic team.
        </div>
      </div>
    </section>
  )
}

export default AboutSection
export {aboutRef}
